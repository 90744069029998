import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    const animateImgs = (removeFrom, addTo) => {
        document
            .querySelectorAll('.' + removeFrom + '.animate')
            .forEach(img => img.classList.remove('animate'));

        document
            .querySelectorAll('.' + addTo)
            .forEach(img => img.classList.add('animate'));
    };

    return (
        <Layout navTheme="light">
            <Seo title="marketing-consulting-services" />
            <div className="marketing-consulting-services">
                <Container className="purple-blob container-1 hero-container">
                    <img
                        className="hero-curve"
                        src={require('../../images/mcs-images/MCS-hero.png')}
                        alt="Star"
                    />
                    <Row>
                        <Col className="hero-half">
                            <p className="hero-subtext yellow-text">
                                Introducing
                            </p>
                            <h1 className="white-text">
                                GlassHive’s Marketing Consulting Services
                            </h1>
                        </Col>
                        <Col></Col>
                        <img
                            className="screenshot"
                            src={require('../../images/mcs-images/screenshot.png')}
                            alt="Star"
                        />
                    </Row>
                </Container>
                <Container className="grow-container">
                    <Row>
                        <Col className="centered-heading">
                            <h2 className="pink-title">Grow with GlassHive</h2>
                        </Col>
                    </Row>
                    <Row className="content-row">
                        <Col>
                            <p className="centered-text">
                                <span className="bold-text">
                                    Let our consultants help you{' '}
                                </span>
                                attract qualified leads, engage prospects, and
                                maximize conversions to grow your business.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="features-container">
                    <Row className="marketing-and-sales-section">
                        <Col className="heading">
                            <h2 className="centered-heading">
                                Here’s What Our Comprehensive Program Includes:
                            </h2>
                        </Col>
                    </Row>
                    <Row className="content-row">
                        <Col>
                            <h3 className="purple-title">Lead Acquisition</h3>
                            <div className="flex-column">
                                <img
                                    className="screenshot program-icon"
                                    src={require('../../svgs/mcs-svgs/one-star.svg')}
                                    alt="Star"
                                />
                                <p className="program-content">
                                    Identify and reach your Target Customer
                                    Profile (TCP) and acquire{' '}
                                    <span className="bold-text">
                                        up to 1,000 new leads per month
                                    </span>
                                    —handpicked to match your audience.
                                </p>
                            </div>
                        </Col>
                        <Col>
                            <h3 className="purple-title">Lead Scrubbing</h3>
                            <div className="flex-column">
                                <img
                                    className="screenshot program-icon"
                                    src={require('../../svgs/mcs-svgs/mcs-sparkles.svg')}
                                    alt="Star"
                                />
                                <p className="program-content">
                                    <span className="bold-text">
                                        Fine-tune your pipeline!
                                    </span>{' '}
                                    Our meticulous lead scrubbing ensures that
                                    only{' '}
                                    <span className="bold-text">
                                        high-quality leads enter your sales
                                        funnel
                                    </span>
                                    , so you can focus on prospects with real
                                    potential.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="content-row">
                        <Col>
                            <h3 className="purple-title">
                                Weekly Social Media Posts
                            </h3>
                            <div className="flex-column ">
                                <img
                                    className="screenshot program-icon"
                                    src={require('../../svgs/mcs-svgs/favorite.svg')}
                                    alt="Star"
                                />
                                <p className="program-content">
                                    <span className="bold-text">
                                        Keep your brand top-of-mind
                                    </span>{' '}
                                    with consistent, engaging content that{' '}
                                    <span className="bold-text">
                                        attracts and converts.
                                    </span>
                                </p>
                            </div>
                        </Col>
                        <Col>
                            <h3 className="purple-title">
                                Monthly Email Campaigns
                            </h3>
                            <div className="flex-column">
                                <img
                                    className="screenshot program-icon"
                                    src={require('../../svgs/mcs-svgs/send.svg')}
                                    alt="Star"
                                />
                                <ul className="program-content">
                                    {' '}
                                    <li className="bold-text">
                                        2 Graphical Emails
                                    </li>
                                    <li className="bold-text">
                                        2 Personalized Text Emails
                                    </li>
                                    <li className="bold-text">
                                        1 Landing Page
                                    </li>
                                    <li className="bold-text">1 Whitepaper</li>
                                    <li className="bold-text">1 Infographic</li>
                                    <li className="bold-text">
                                        1 Engaging Video
                                    </li>{' '}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className="content-row">
                        <Col>
                            <h3 className="purple-title">
                                Bi-Weekly Check-In Calls
                            </h3>
                            <div className="flex-column">
                                <img
                                    className="screenshot program-icon"
                                    src={require('../../svgs/mcs-svgs/phone.svg')}
                                    alt="Star"
                                />
                                <p className="program-content">
                                    <span className="bold-text">
                                        Stay aligned with bi-weekly calls to
                                        review your progress
                                    </span>
                                    , adjust strategies, and make the most of
                                    your campaign
                                </p>
                            </div>
                        </Col>
                        <Col>
                            <h3 className="purple-title">
                                Weekly Campaign & Lead
                            </h3>
                            <div className="flex-column">
                                <img
                                    className="screenshot program-icon"
                                    src={require('../../svgs/mcs-svgs/mcs-graph.svg')}
                                    alt="Star"
                                />
                                <p className="program-content">
                                    <span className="bold-text">
                                        Generation Reports
                                    </span>{' '}
                                    Stay informed and track your progress with{' '}
                                    <span className="bold-text">
                                        detailed insights delivered directly to
                                        you
                                    </span>{' '}
                                    every week.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="buzz-container">
                    <Row className="marketing-and-sales-section">
                        <Col className="heading">
                            <img
                                className="report-image"
                                src={require('../../svgs/mcs-svgs/report.svg')}
                                alt="Star"
                            />
                        </Col>
                        <Col className="heading">
                            <h2 className="pink-text">
                                MSP Marketing & Sales Made Possible
                            </h2>
                            <p>
                                <strong>
                                    Did you know that MSPs using GlassHive
                                    effectively and launching a tailored
                                    marketing plan see an average revenue
                                    increase of $179,200?
                                </strong>{' '}
                                Don’t leave your success to chance—our marketing
                                consulting services provide the expertise and
                                guidance you need to achieve these results.
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container className="form-container">
                    <img
                        className="form-curve"
                        src={require('../../svgs/mcs-svgs/form-curve.svg')}
                        alt="Star"
                    />
                    <Row className="marketing-and-sales-section">
                        <Col className="heading">
                            <img
                                className="report-image"
                                src={require('../../svgs/mcs-svgs/megaphone.svg')}
                                alt="Star"
                            />
                            <h2 className="centered-heading yellow-text">
                                Start Generating Qualified Leads for $2500 a
                                Month!{' '}
                            </h2>
                        </Col>
                    </Row>
                    <Row className="form-section">
                        <Col className="form">
                            <AppForm
                                formName="coop-form"
                                submitBtnText="Sign up now!"
                                className="coop-form"
                                formCategory="Careers"
                            >
                                <AppInput
                                    fieldName="firstName"
                                    type="text"
                                    labelText="Name*"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="company"
                                    type="text"
                                    labelText="Company"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="title"
                                    type="text"
                                    labelText="Title"
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    labelText="Email*"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="phone"
                                    type="phone"
                                    labelText="Phone*"
                                    requiredField
                                />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
